import {
  ref, watch, computed, onMounted,
} from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function productBrandHandler() {
  // Use toast
  const toast = useToast()

  const refProductBrandListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Nombre', sortable: false },
    { key: 'photo', label: 'Foto', sortable: false },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(10)
  const totalProductBrands = ref(0)
  const currentPage = ref(1)

  const refetchData = () => {
    refProductBrandListTable.value.refresh()
  }

  watch([currentPage], () => {
    refetchData()
  })

  const fetchProductBrands = (ctx, callback) => {
    store
      .dispatch('app-products-brands/fetchProductBrands', {
        page: currentPage.value,
      })
      .then(response => {
        const { results } = response.data.payload

        callback(results)
        totalProductBrands.value = results.length
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching quotations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }


  return {
    fetchProductBrands,

    tableColumns,
    perPage,
    currentPage,
    totalProductBrands,

    refProductBrandListTable,
    refetchData,
  }
}
