<template>
  <div>
    <!-- Table Container Card -->
    <!--    <add-product-brand-sidebar-->
    <!--      :is-add-new-product-brand-sidebar-active.sync="isAddNewProductBrandSidebarActive"-->
    <!--      @refetch-data="refetchData"-->
    <!--    />-->
    <brand-handler-sidebar
      v-model="isBrandHandlerSidebarActive"
      :brand="brand"
      :clear-brand-data="clearBrandData"
      @add-brand="addBrand"
      @update-brand="updateBrand"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <div class="">
              <h3>Total de marcas</h3> <small> (Hay {{ totalProductBrands }} marcas)</small>
            </div>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end  mb-1 mb-md-0">
              <b-button
                variant="primary"
                @click="isBrandHandlerSidebarActive = true"
              >
                Crear
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refProductBrandListTable"
        class="position-relative"
        :items="fetchProductBrands"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No se encontraron registros coincidentes"
      >

        <!-- Column: Client -->
        <template #cell(photo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-img
                rounded
                thumbnail
                fluid
                :src="data.item.photo"
                alt="Blog dramox"
              />
            </template>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <span v-can-permissions="'Editar marcas'">
              <feather-icon
                :id="`invoice-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="editBrand(data.item)"
              />
              <b-tooltip
                title="Editar"
                class="cursor-pointer"
                :target="`invoice-row-${data.item.id}-send-icon`"
              />
            </span>

            <span v-can-permissions="'Editar marca'">
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                icon="TrashIcon"
                size="16"
                class="mx-1 cursor-pointer"
                @click="deleteBrand(data.item)"
              />
              <b-tooltip
                title="Eliminar"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />
            </span>

          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-end justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalProductBrands"
              per-page="10"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BPagination,
  BTooltip,
  BImg,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import productBrandHandler from '@/views/dramox/product/brand/productBrandHandler'
import BrandHandlerSidebar from '@/views/dramox/product/brand/sidebar/brandHandlerSidebar'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import brandStoreModule from '@/views/dramox/product/brand/brandStoreModule'

export default {
  components: {
    BrandHandlerSidebar,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BPagination,
    BTooltip,
    BButton,
    BImg,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const STORE_MODULE_NAME = 'app-products-brands'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, brandStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const toast = useToast()

    const showNotification = ({ title, icon, variant }) => {
      toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    }
    const isBrandHandlerSidebarActive = ref(false)

    const {
      // fetchUsers,
      tableColumns,
      totalProductBrands,

      perPage,
      currentPage,

      perPageOptions,
      refProductBrandListTable,
      refetchData,
      fetchProductBrands,

    } = productBrandHandler()

    const blankBrand = {
      name: '',
      photo: null,
    }
    const brand = ref(JSON.parse(JSON.stringify(blankBrand)))
    const clearBrandData = () => {
      brand.value = JSON.parse(JSON.stringify(blankBrand))
    }

    const editBrand = item => {
      const { photo: image, ...rest } = item
      brand.value = { ...rest, photo: null, showImage: image }
      isBrandHandlerSidebarActive.value = true
    }
    const addBrand = item => {
      const formData = new FormData()
      formData.append('name', item.name)
      formData.append('photo', item.photo)

      store.dispatch('app-products-brands/addBrand', { body: formData })
        .then(() => {
          refetchData()
          isBrandHandlerSidebarActive.value = false
          showNotification({ title: 'Marca creada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const updateBrand = item => {
      const formData = new FormData()
      formData.append('name', item.name)
      formData.append('photo', item.photo)

      store.dispatch('app-products-brands/updateBrand', { id: item.id, body: formData })
        .then(() => {
          refetchData()
          isBrandHandlerSidebarActive.value = false
          showNotification({ title: 'Marca actualizada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    const deleteBrand = item => {
      store.dispatch('app-products-brands/deleteBrand', { id: item.id })
        .then(() => {
          refetchData()
          showNotification({ title: 'Marca eliminada con éxito', icon: 'CheckCircleIcon', variant: 'success' })
        })
        .catch(() => {
          showNotification({ title: 'Oops!, ocurrió un error', icon: 'AlertTriangleIcon', variant: 'danger' })
        })
    }

    return {
      isBrandHandlerSidebarActive,
      brand,
      clearBrandData,

      tableColumns,
      perPage,
      currentPage,
      totalProductBrands,

      perPageOptions,
      refProductBrandListTable,
      refetchData,

      fetchProductBrands,

      editBrand,
      addBrand,
      updateBrand,
      deleteBrand,
    }
  },
}
</script>
