import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProductBrands(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/product/brands')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addBrand(ctx, { body }) {
      return new Promise((resolve, reject) => {
        axios
          .post('admin/product/brand/store', body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateBrand(ctx, { id, body }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/product/brand/${id}/update`, body)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteBrand(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`admin/product/brand/${id}/delete `)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
